import React, {Component} from "react";
import LeadershipTeam from "../components/LeadershipTeam";
import ContactUsComponent from "../components/ContactUsComponent";
import WireframeLogo1 from "../icons/custom/wireframelogo2/WireframeLogo1";
import WireframeLogo2 from "../icons/custom/wireframelogo2/WireframeLogo2";
import WireframeLogo3 from "../icons/custom/wireframelogo2/WireframeLogo3";
import WireframeLogo4 from "../icons/custom/wireframelogo2/WireframeLogo4";
import WireframeLogo5 from "../icons/custom/wireframelogo2/WireframeLogo5";
import WireframeLogo6 from "../icons/custom/wireframelogo2/WireframeLogo6";
import WireframeLogo7 from "../icons/custom/wireframelogo2/WireframeLogo7";
import WireframeLogo8 from "../icons/custom/wireframelogo2/WireframeLogo8";
import WireframeLogo9 from "../icons/custom/wireframelogo2/WireframeLogo9";
import WereHiringSection from "../components/WereHiringSection";
import {WHY_SCOPE} from "../utils/dummy";
import FadeInOnScroll from "../components/wrappers/FadeInOnScroll";
import SvgHandPeace from "../icons/custom/SvgHandPeace";
import VericalHero from "../components/VericalHero";
import OurProcess from "../components/OurProcess";
import TestimonialsSeciton from "../components/TestimonialsSection";
import Shape5 from "../icons/pack1/Shape5";
import Shape63 from "../icons/pack1/Shape63";
import {Link} from "react-router-dom";

class Wrapped extends Component {
    render() {
        return (
            <div style={{padding: '16vh 0 0 0', overflow: "hidden"}}>
                <div className={'page-h-padding relative'}>
                    <FadeInOnScroll delay={200} triggerImmediately>
                        <div className='row-ac' style={{marginBottom: this.props.mobile ? -12 : -24}}>
                            <h2 className={`heading1 mt-0 mb-0`}
                                style={{zIndex: 2}}>
                                Our 2024 Recap
                            </h2>
                            {!this.props.mobile && (
                                <Shape63 style={{
                                    width: this.props.mobile ? 60 : 120,
                                    height: this.props.mobile ? 60 : 120,
                                    marginTop: -32
                                }} className={'rotate-rev-slow'}/>
                            )}
                        </div>
                    </FadeInOnScroll>
                    <FadeInOnScroll delay={400} triggerImmediately>
                        <div className='row-ac mb-24'>
                            <p className='title1' style={{zIndex: 2}}>
                                Take a look at some of our projects from the past year.
                            </p>
                        </div>
                    </FadeInOnScroll>
                    <img className='absolute rotate-rev-slow' src={require('../img/Holographic 3D shape 57.png')}
                         style={{
                             right: this.props.mobile ? -120 : 0,
                             width: this.props.mobile ? 400 : 600,
                             bottom: this.props.mobile ? -100 : -300,
                             opacity: .05,
                             zIndex: -1
                         }}/>
                </div>
                <FadeInOnScroll triggerImmediately delay={800}>
                    <div
                        className='page-h-padding col-ac'
                        style={{
                            paddingBottom: '10vh',
                            boxSizing: 'border-box',
                            width: this.props.mobile ? '100%' : '100%'
                        }}
                    >
                        <div
                            style={{
                                position: "relative",
                                width: "100%",
                                maxWidth: "1280px",
                                aspectRatio: "16 / 9", // Ensure 16:9 aspect ratio
                            }}
                        >
                            <iframe
                                src="https://player.vimeo.com/video/1039432566?h=cc761be356&badge=0&autopause=0&player_id=0&app_id=58479"
                                frameBorder="0"
                                allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%"
                                }}
                                title="Scope Labs 2024 Showreel NV"
                            ></iframe>
                        </div>
                    </div>
                </FadeInOnScroll>
                <FadeInOnScroll triggerImmediately delay={1200}>
                    <div className={'row-ac-jc'} style={{gap:12}}>
                        <Link to={'/'} className={this.props.mobile ? 'mt-8' : 'mt-'}>
                            <button className='primary-button body3-bold uppercase'>
                                Go to Home
                            </button>
                        </Link>
                        <Link to={'/work'} className={this.props.mobile ? 'mt-8' : 'mt-'}>
                            <button className='primary-button body3-bold uppercase'>
                                View Case Studies
                            </button>
                        </Link>
                    </div>
                </FadeInOnScroll>
                <div className=''>
                    <ContactUsComponent mobile={this.props.mobile} darkBody={false}/>
                </div>
            </div>
        );
    }
}

export default Wrapped;
